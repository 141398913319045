.App {
  text-align: center;
}

body{
  overflow-x: hidden;
  background: #efefef;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header
{
  background: #fff !important;
  text-align: right;
}
.headerText
{
  width: 95%;
}

.sidebars
{
  position: fixed;
  z-index: 10000;
}
.main-sec
{
  width: 95%;
  margin: auto;
  margin-top: 4em;
}
.home-card
{
  border: 1px solid #dfdfdf;
  padding: 2em;
  background: #fff;
  border-radius: 5px;
  margin-top: 2em;
}
.card-table{
  padding: 2em;
  border: 1px solid #dfdfdf;
  background: #fff;
  border-radius: 5px;
  margin-top: 6em;
}
.icone
{
  max-width: 100%;
}

.myContainer {
  position: relative;
  width: 100%;
  height: 100vh; /* Make the container take the full viewport height */
}

.fixed-button {
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    padding: 10px 20px;
    //background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2000;
}
.btn-extn
{
  position: fixed;
  bottom: 54px; /* Adjust the distance from the bottom as needed */
  right: 21px; /* Adjust the distance from the right as needed */
  z-index: 700;
  background: #fff;
  padding: 10px 0px;
  cursor: pointer;
  display: none;
  z-index: 700;
  margin-bottom: 10px;
  width: 90px;
  color: #000;
  border-top: 1px solid #999;
}
.btn-extn2
{
  position: fixed;
  bottom: 96px; /* Adjust the distance from the bottom as needed */
  right: 21px; /* Adjust the distance from the right as needed */
  z-index: 700;
  background: #fff;
  padding: 10px 0px;
  cursor: pointer;
  display: none;
  z-index: 700;
  margin-bottom: 10px;
  width: 90px;
  color: #000;
  //border-top: 1px solid #999;
}

@media  screen and (max-width:800px){
       
   .btn-extn
    {
      bottom: 114px;
    }
    .btn-extn2
    {
      bottom: 156px;
    }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #999; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666; 
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #a70b0f;
}

.content {
  margin: 0% auto;
  align-items: center;
  background-color: $darkGrey;
  display: flex;
  height: 300px;
  margin-top: 10%;
  width: 600px;
  justify-content: center;
}

.spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner div {
  transform-origin: 40px 40px;
  animation: spinner 1.2s linear infinite;
}
.spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.loading-text
{
  color: #fff;
  margin-top: -8%;
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.myOverlay
{
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .3);
  z-index: 2500;
}
.myModal
{
  margin: 10% auto;
  background: #efefef;
  width: 50%;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 2em;
  text-align: left;
}
.myModal2
{
  margin: 5% auto;
  background: #efefef;
  width: 50%;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 2em;
  text-align: left;
}

@media  screen and (max-width:800px){
       
   .myModal
    {
      width: 90%;
    }
    .myModal2
    {
      width: 90%;
    }
}

.auth-form
{
  width: 50%;
  margin: 5% auto;
  padding: 2em;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

@media  screen and (max-width:800px){
       
   .auth-form
    {
      width: 90%;
    }
}

@media  screen and (max-width:800px){
       
   .sidebars
   {
      position: relative !important;
      display: none !important;
      //opacity: 0;
   }
   .btmNav
   {
    position: fixed !important;
    display: block !important;
    opacity: 1;
   }

}

.btmNav
 {
  position: relative !important;
  display: none !important;
  //opacity: 0;
 }

 @media screen and (max-width:800px){

      .fixed-button {
        bottom: 80px; /* Adjust the distance from the bottom as needed */
        right: 20px; /* Adjust the distance from the right as needed */
    }
}

 @media  screen and (max-width:800px){
       
   .sidebar
   {
      position: relative !important;
      display: none !important;
      //opacity: 0;
   }

   .progressBar
   {
      display: none;
   }

   .btmNav
   {
    position: fixed !important;
    display: block !important;
    opacity: 1;
   }
}
.myMobileNav
{
  width: 100%;
  margin: 0px auto !important;
}
.myMobileNavLink
{
  color: #efefef;
  text-decoration: none;
}

.myMobileNavLink .active
{
  color: red;
}

.icone
{
  max-width: 80%;
}

.myMobileNavLink:hover
{
  color: #afafaf;
}